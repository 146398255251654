// **  Initial State
const initialState = {
    userData: {}
}

const authReducer = (state = initialState, action) => {
    const { type, data, config } = action

    switch (type) {
        case "LOGIN": {
            const { storageTokenKeyName, storageRefreshTokenKeyName } = config

            return {
                ...state,
                userData: data,
                [storageTokenKeyName]: action[storageTokenKeyName],
                [storageRefreshTokenKeyName]: action[storageRefreshTokenKeyName]
            }
        }
        case "LOGOUT":
            const obj = { ...action }
            delete obj.type
            return { ...state, userData: {}, ...obj }
        case "UPDATE_USER":
            return {
                ...state,
                userData: data
            }
        default:
            return state
    }
}

export default authReducer
