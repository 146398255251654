// ** Initial State
const initialState = {
    allData: [],
    data: [],
    next: '',
    previous: '',
    total: 1,
    params: {},
    selectedUser: null
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_ALL_DATA':
            return { ...state, allData: action.data, next: action.next, previous: action.previous }
        case 'GET_DASHBOARD_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}
export default users